import {Component, OnInit} from '@angular/core';
import {PortDTO} from "../../service/dto";
import {NgEventBus} from "ng-event-bus";
import {TitleEvent} from "../../layout/header/header.component";
import {PortService} from "../../service/port.service";
import {exhaustMap, first, Observable, ReplaySubject, tap} from "rxjs";
import {ActivatedRoute} from "@angular/router";
import {ConfigService} from "../../service/config.service";
import {DomSanitizer, SafeResourceUrl} from "@angular/platform-browser";

@Component({
  selector: 'app-port-dashboard',
  templateUrl: './port-dashboard.component.html',
  styleUrl: './port-dashboard.component.scss'
})
export class PortDashboardComponent implements OnInit {
  data$ = new ReplaySubject();
  data: Observable<PortDTO>;
  port: PortDTO;
  dashboardUrl: SafeResourceUrl

  constructor(private eventBus: NgEventBus,
              private sanitizer: DomSanitizer,
              private route: ActivatedRoute,
              private configService: ConfigService,
              private portService: PortService) {
    const id = this.configService.selectedPort.id
    this.configService.getPortConfig('DASHBOARD_URL', id).pipe(
      tap(x => this.dashboardUrl = this.sanitizer.bypassSecurityTrustResourceUrl(x?.value)),
      tap(x => console.log("Kkk")),
      first()
    ).subscribe()
    this.data = this.data$.pipe(
      exhaustMap(x => this.portService.getById(+id, {berths: true}).pipe(
          tap(c => this.port = c),
          tap(c => this.eventBus.cast('page:title', {
            pageTitle: 'Statistiche porto di ' + c.name,
            // backUrl: '/port/dashboard',
          } as TitleEvent)),
          tap(x => console.log("222")),
          first()
        )
      )
    );

    // this.data = forkJoin([port$, conf$]).pipe(
    //   tap(c=> console.log("ASAS"))
    // )
  }

  ngOnInit(): void {
    this.data$.next(null)
  }

}
