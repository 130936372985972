@if (data | async; as d) {
  <iframe [src]="dashboardUrl" class="h-full w-full rounded"><p>Your browser does not support iframes.</p></iframe>
} @else {
  <ng-container *ngTemplateOutlet="waiting"></ng-container>

}

<ng-template #waiting>
  <div class="flex justify-center items-center w-full h-full">
    <app-spinner size="XL"></app-spinner>
  </div>
</ng-template>
